import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

// Libraries
import CookieConsent from 'react-cookie-consent'

// Theme
import Theme from 'styles/Theme'

// Components
import Footer from './Footer'
import Header from './Header'

// CSS
import 'styles/all.scss'

const Content = styled.div`
  padding-top: 100px;
`

const CookieLink = styled(Link)`
  color: ${(props) => props.theme.color.text.light};
  text-decoration: underline;
`

const Layout = ({ children, noChurch }) => {
  const [isSticky, setSticky] = useState(false)
  const [isScrollable, setIsScrollable] = useState(true)

  const handleScroll = () => {
    setSticky(window.scrollY > 0)
  }

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [handleScroll])

  return (
    <Theme isScrollable={isScrollable}>
      <Header isSticky={isSticky} setIsScrollable={setIsScrollable} />
      <Content isSticky={isSticky}>{children}</Content>
      <Footer noChurch={noChurch} />

      <CookieConsent
        location="bottom"
        style={{ backgroundColor: '#B09C79', color: '#FFFFFF' }}
        buttonText="Akkoord"
        buttonStyle={{ backgroundColor: '#000', color: '#fff' }}
      >
        <div className="px-lg-5 font-size-sm">
          <div>
            We gebruiken cookies om ervoor te zorgen dat onze website zo soepel
            mogelijk draait. Als u doorgaat met het gebruiken van de website,
            gaan we er vanuit dat u ermee instemt.{' '}
            <CookieLink to="/privacyverklaring">
              Lees hier de privacyverklaring
            </CookieLink>
          </div>
        </div>
      </CookieConsent>
    </Theme>
  )
}

export default Layout
